.organizationRoles {
  height: calc(100vh - 250px);
}
.roleCollapse .roleCollapsePanel {
    margin-bottom: 10px;
    overflow: hidden;
    background: #fff;
    border: 1px solid #D9DEEC !important;
    border-radius: 6px !important;
}

.panelContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  background-color: #F8F8F8;
  height: 3.5rem;
  padding-left: 1rem;
  margin-bottom: 10px;
  box-shadow: 0px 1px 0px #D9DEEC;
}

.addIcon {
  padding-right: 0.5rem;
  font-style: normal;
}

.extraAssignIcon {
  line-height: 56px;
  display: inline;
  vertical-align: middle;
}

.moreAction {
  padding-right: 0.25rem;
  font-size: 1.5rem;
}

.assignToUsersForm {
  width: 300px;
}