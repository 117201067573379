
@import "../../Application/Variables";


.PageHeader {
  border-bottom: @border-width-base @border-color-base @border-style-base;
}

.LoginPopover {

  &.@{ant-prefix}-popover{
    &-inner-content {
      padding: 0;
      background: red;
    }
  }
}

.iconButton {
  width: 37px;
  height: 37px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  cursor: pointer;

  &:hover {
    .iconFont {
      color: @primary-color;
    }
    background-color: #deebd2;
  }

  & + .iconButton {
    margin-left: 8px;
  }

  .iconFont {
    color: #b2b0c7;
    font-size: 24px;
  }
}

.notification {
  padding: 24px 0;
  width: 320px;
  .notificationItem {
    transition: all 0.3s;
    padding: 12px 24px;
    cursor: pointer;
    &:hover {
      background-color: #f9f9fc;
    }
  }
  .clearButton {
    text-align: center;
    height: 48px;
    line-height: 48px;
    cursor: pointer;
  }
}

.notificationPopover {
  :global {
    .ant-popover-inner-content {
      padding: 0;
    }
    .ant-popover-arrow {
      display: none;
    }
    .ant-list-item-content {
      flex: 0;
      margin-left: 16px;
    }
  }
}