@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import './Variables.less';

.@{ant-prefix}-menu {
  &:not(&-horizontal) &-item-selected {
    background-color: @menu-item-active-bg;
    border-left: 3px solid @menu-highlight-color;
  }
  &-item,
  &-submenu-title {
    .@{ant-prefix}-item-icon,
    .@{iconfont-css-prefix} {
      vertical-align: middle;
    }
  }

  &-inline,
  &-vertical,
  &-vertical-left {
    border-right: none;
  }
}

// List

body{
  font-family: Roboto, Arial, Avenir, SansSerif, serif;
}

.@{ant-prefix}-form-item-label{
  font-size: 16px;
  color: @text-color;
}

.@{ant-prefix}-popover-arrow-content{
  display: none !important;
}

.@{ant-prefix}-modal-body {
	padding: 16px;
}

/** Scrollable **/
@bg-color-scroll: #aab2bf;
@bg-color-scroll-2: #d9d9d9;

.scrollbar-input(@width-input, @height-input, @radius-input) {
	&::-webkit-scrollbar {
		width: @width-input;
		height: @height-input;
	}
	&::-webkit-scrollbar-thumb {
		background: @bg-color-scroll;
		border-radius: @radius-input;
	}
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 @radius-input @bg-color-scroll-2;
		border-radius: @radius-input;
	}
	scrollbar-color: @bg-color-scroll @bg-color-scroll-2;
	scrollbar-width: thin;
}
.custom-scrollbar {
	.scrollbar-input(6px, 6px, 4px);
	overflow: overlay;
	&:hover {
		overflow: overlay;
	}
	@-moz-document url-prefix() {
		&:hover {
			overflow: scroll;
		}
	}
}

.listItemBordered {
	border-style: solid;
	border-color: #d9deec;
	border-width: 1px;
	border-radius: 6px;
	padding: 10px;
	margin-bottom: 10px;
}

.paginationBottom {
	display: flex;
	justify-content: center;
	position: fixed;
	bottom: 26px;
}