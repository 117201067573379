.OrganizationUsers {
    height: 100%;
}

.UserList {
    height: calc(100vh - 280px);
}

.UserItem {
    border-style: solid;
    border-color: #d9deec;
    border-width: 1px;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 10px;
    min-width: 1024px;
}

.AlignMiddle {
    height: 32px;
    display: table-cell;
    vertical-align: middle;
}

.Pagination {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 26px;
}

.AddUser {
    font-size: 25px;
    cursor: pointer;
    margin: 2px 10px;
}